import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`id Unique identifier for this account`}</p>
    <p>{`name Account name`}</p>
    <p>{`owner Identifier of the user that owns this account`}</p>
    <p>{`industry_code Six-digit North American Industry Classification System (NAICS) code`}</p>
    <p>{`industry Industry of account`}</p>
    <p>{`employees Number of employees`}</p>
    <p>{`rating Rating of the account`}</p>
    <p>{`fax Fax number of the account`}</p>
    <p>{`phone Phone number of the account
website URL of the company’s Web site
annual_revenue Annual revenue of the account
billing_address Billing address of the account to deliver the shipment
shipping_address Shipping address of the account to deliver the shipment
created ISO 8601 timestamp indicating when the account object was created.
modified ISO 8601 timestamp indicating when the account object was modified.
description Additional details about the Account
type Type of object. Always account.
raw Underlying object retrieved from the service.
api Always crm.
href The absolute URL to get the object’s metadata.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      